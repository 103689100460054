.Toastify__toast-container--top-right {
  top: 40px;
  right: 30px;
}

.Toastify__toast {
  padding: unset;
  border-radius: unset;
  box-shadow: unset;
  margin-bottom: unset;
  background: unset;
}
